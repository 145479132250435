import MagureApiService from './magure-api-service';

const ContactUsService = (() => {
	'use strict';

	var contacUs;

	contacUs = (obj) => {
		return MagureApiService.post('contactus', obj);
	};

	return {
		contacUs: contacUs
	};
})();

export default ContactUsService;