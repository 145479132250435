<template>
  <div
    data-aos="fade-up"
    data-aos-delay="300"
    class="contactForm px-6 sm:px-12 py-16 rounded shadow-lg w-full"
  >
    <form @submit.prevent="send()">
      <div class="form-group">
        <label
          data-aos="zoom-in-right"
          data-aos-delay="400"
          data-aos-anchor-placement="top-bottom"
          for="Name"
          class="text-left w-full font-bold"
        >Enter your name</label>
        <input
          data-aos="zoom-in-right"
          data-aos-delay="500"
          data-aos-anchor-placement="top-bottom"
          class="form-control"
          v-model="name"
          placeholder
          title="Enter your name"
          type="text"
          :class="{ 'is-invalid': $v.name.$error }"
        />
      </div>
      <div class="form-group">
        <label
          data-aos="zoom-in-right"
          data-aos-delay="600"
          for="email"
          class="text-left w-full font-bold"
        >Enter your email</label>
        <input
          data-aos="zoom-in-right"
          data-aos-delay="700"
          class="form-control"
          v-model="emailAddress"
          placeholder
          title="Enter your email address"
          type="email"
          :class="{ 'is-invalid': $v.emailAddress.$error }"
        />
      </div>
      <div class="form-group">
        <label
          data-aos="zoom-in-right"
          data-aos-delay="800"
          for="Name"
          class="text-left w-full font-bold"
        >Enter your company name</label>
        <input
          data-aos="zoom-in-right"
          data-aos-delay="900"
          class="form-control"
          v-model="company"
          placeholder
          title="Enter your company name"
        />
      </div>
      <div class="form-group">
        <label
          data-aos="zoom-in-right"
          data-aos-delay="1000"
          for="Name"
          class="text-left w-full font-bold"
        >Enter your message</label>
        <textarea
          data-aos="zoom-in-right"
          data-aos-delay="1100"
          class="form-control"
          v-model="message"
          placeholder
          title="Enter message"
          rows="5"
        ></textarea>
      </div>

      <!--<div class="flex items-center">
                <input class="form-control checkbox w-2/5" v-model="disclaimer" type="checkbox"  />

                <label class="font-bold mb-0 ml-2">Disclaimer</label>
            </div>
            <div>
                <p class="text-light-gray text-left text-small">All information provided will remain confidential and will not be shared by any other third party.</p>
      </div>-->
      <div class="text-left">
        <button
          data-aos="fade-right"
          data-aos-delay="1200"
          data-aos-once="true"
          class="btn btn-primary"
          :state="submitButtonState"
          :isConfirm="true"
          :isBorderRadiud="true"
        >Send Message</button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
// import BaseButton from '../BaseButton.vue';

import ContactusService from "../../services/contact-us-service";
import ButtonState from "../../lib/button-state";

export default {
  components: {},
  data() {
    return {
      emailAddress: "",
      company: "",
      message: "",
      name: "",
      disclaimer: "",
      submitButtonState: ButtonState.Ready
    };
  },
  validations: {
    name: { required, minLength: minLength(2) },
    emailAddress: { required, email }
  },
  methods: {
    send() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.submitButtonState = ButtonState.Loading;
      let ContactObject = {
        name: this.name,
        email: this.emailAddress,
        contact_number: "123456789",
        company_name: this.company,
        project_description: this.message
      };
      ContactusService.contacUs(ContactObject)
        .then(res => {
          let response = res.data;
          alert(response.message);
          this.emailAddress = "";
          this.company = "";
          this.name = "";
          this.message = "";
          this.$nextTick(() => {
            this.$v.$reset();
          });
        })
        .catch(err => {
          let errorMsg = err.message
            ? err.message
            : "Some thing went wrong, Please Try again";
          alert(errorMsg);
          this.submitButtonState = ButtonState.Ready;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/Variables.scss";

.btn {
  height: 50px !important;
  font-size: 1em;
  border-radius: 50px !important;
  width: 190px;

  // @media (max-width:500px) {
  //     width: 180px;
  // }
}
.form-group {
  margin-bottom: 1.5rem !important;
}
label {
  font-size: $font-size-14px;
}
.checkbox {
  width: 1em;
  height: 1em;
}
input:checked {
  background-color: black;
}
</style>