<template>
  <div class="exclude-navbar">
    <container-section class="pt-16">
      <div class="row pb-10">
        <div class="col-sm-12 col-md-6">
            <section-left />
        </div>
        <div class="col-sm-12 col-md-6 text-center">
          <contact-form />
        </div>
      </div>
    </container-section>

    <container-section :containerClass="'container-fluid'" :sectionHeight="false">
      <div class=" pt-20 contact-footer relative">
          <Footer></Footer>
        </div>
    </container-section>
  </div>
</template>

<script>
import ContactForm from '../components/ContactUs/ContactForm.vue'
import sectionLeft from '../components/ContactUs/sectionLeft.vue'
import ContainerSection from '../components/ContainerSection.vue'
import Footer from '../components/Home/Footer.vue'
export default {
  title: 'Magure - Contact us',

  components: { 
    sectionLeft,
    ContainerSection,
    ContactForm,
    Footer
  },

}
</script>

<style lang="scss" scoped>
@import "../styles/Pages/ContactUs.scss";
</style>