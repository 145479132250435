<template>
  <div class="sectionLeft">
    <div class="secitonTitle slide-title-large">
      <div data-aos="fade-up" data-aos-delay="300">Lets build</div>
      <div data-aos="fade-up" data-aos-delay="350">something</div>
      <div data-aos="fade-up" data-aos-delay="400">together.</div>
    </div>
    <div class="sectionImage w-4/5 mt-2">
      <img
        data-aos="zoom-in-right"
        data-aos-delay="450"
        src="../../assets/images/connectandBuild.gif"
        alt
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.sectionLeft {
  .sectionImage > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>